.footer {
  background-color: rgba(0, 0, 0, 0.35);
  height: 100px;
  bottom: 0px;
  position: absolute;
  width: calc(1920px);
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 25px;
  .news {
    flex: 1;
    overflow: hidden;
    font-size: 30px;
    .item {
      transform: translateX(calc(100% + 20px));
    }
    .item.show {
      animation: slide-in 0.5s forwards;
    }
  }
  .weather {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    svg {
      margin-left: 15px;
      fill: white;
      max-height: 50px;
      max-width: 50px;
      path {
        transform: scale(1.5) translate(-18px, -18px);
      }
    }
  }
  .time {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    width: 330px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .dateformat {
      line-height: 1;
      text-align: right;
      margin-right: 15px;
    }
    .timeformat {
      font-size: 50px;
    }
  }
}
@keyframes slide-in {
  100% {
    transform: translateX(0);
  }
}
